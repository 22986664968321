import request from '@/common/utils/request.js'
import Vue from 'vue'
import { setInfo, setToken } from '@/api/cookies'

// 手机密码登录
async function loginPcUser(as, bs, cs) {
  let data = await request({
    url: '/stu/front/loginPcUser',
    method: 'POST',
    params: {
      mobile: as,
      password: Vue.prototype.encrypt(bs),
      userSource: cs,
      type: 1,
    },
  })

  if (data.code == 0) {
    await setToken(data.data.token)
    await setInfo(data.data.user)
    localStorage.setItem('userId', data.data.user.id)
    getUserId(data.data.user.id)
  }
  return data
}

async function getMClassCourseListNum() {
  let data = await request({
    url: '/kecheng/pc/classes/getMClassCourseListNum',
    method: 'GET',
  })
 
  return data
}
/**
 * 登录日志记录
 * @returns type 	1 Pc端  2M端
 */
function getUserId(uId) {
  let data = request({
    url: `/stu/front/getUserId`,
    method: 'POST',
    params: {
      uId: uId,
      type: 1,
    },
  })
  return data
}
/**
 * 退出登录日志
 * @returns type 	1 Pc端  2M端
 */
async function logOut() {
  let data = await request({
    url: `/stu/front/logOut`,
    method: 'POST',
    params: {
      type: 1,
    },
  })
  localStorage.clear()
  return data
}
// 动态码
function loginSmsCode(as, cs) {
  let data = request({
    url: '/stu/account/loginSmsCode',
    method: 'GET',
    params: {
      tel: as,
      smsTmplName: cs,
    },
  })
  return data
}
//  所属省份 接口
function getAreaFuList(as, cs) {
  let data = request({
    url: '/kecheng/area/getAreaFuList',
    method: 'GET',
    
  })
  return data
}
// 手机短信验证码登录
async function loginPcUserSms(as, bs, cs, ds) {
  let data = await request({
    url: '/stu/front/loginPcUserSms',
    method: 'POST',
    params: {
      mobile: as,
      token: bs,
      code: cs,
      type: 1,
      userSource: ds,
    },
  })
  if (data.code == 0) {
    await setToken(data.data.token)
    await setInfo(data.data.user)
    localStorage.setItem('userId', data.data.user.id)
    getUserId(data.data.user.id)
  }
  return data
}
// 学生提交专题页数据提交
async function registerStudentJXU(item) {
  let data = await request({
    url: '/stu/front/registerStudentJXU',
    method: 'POST',
    params:item,
  })
 
  return data
}
/**
 * 添加浏览日志
 * @returns message 	内容
 * @returns urlName 	页面路径
 */
async function addBrowse(message, urlName, userId) {
  let data = await request({
    url: `/stu/browse/addBrowse`,
    method: 'POST',
    params: {
      source: 'PC端',
      message: message,
      urlName: urlName,
      userId: userId,
    },
  })
  return data
}
/**
 * 查询协议列表 不分页
 * 1 注册协议 2购买协议
 */
async function getAgreementList(type) {
  let data = await request({
    url: `/kecheng/agreement/getAgreementList`,
    method: 'GET',
    params: {
      type: type,
    },
  })
  return data
}
/**
 *班型 生成订单 查询是否有协议
 * 1 注册协议 2购买协议
 */
async function getPcAgreement(classesId) {
  let data = await request({
    url: `/kecheng/classesAgreement/getPcAgreement`,
    method: 'GET',
    params: {
      classesId: classesId,
    },
  })
  return data
}
/**
 *新里程协议
 * tenantId   机构Id 
    type  协议类型  1 注册协议  2购买协议
 */
async function getAgreementDetails(tenantId, type) {
  let data = await request({
    url: `/kecheng/agreement/getAgreementDetails`,
    method: 'GET',
    params: {
      tenantId: tenantId,
      type: type,
    },
  })
  return data
}
export {
  loginPcUser,
  loginSmsCode,
  getMClassCourseListNum,
  loginPcUserSms,
  logOut,
  addBrowse,
  getAgreementList,
  getPcAgreement,
  getAgreementDetails,
  getAreaFuList,
  registerStudentJXU
}
